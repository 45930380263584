import clsx from 'clsx';

import type { ComponentProps, FC, ReactNode } from 'react';

import styles from './text.module.scss';

export type TextColor =
  | 'default'
  | 'inherit'
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark';

export interface TextProps extends ComponentProps<'p'> {
  /** Changes the element type */
  as?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'p'
    | 'sub'
    | 'sup'
    | 'span'
    | 'dt'
    | 'dd'
    | 'figcaption'
    | 'div';
  /** Changes the font size */
  size?: 'T200' | 'T300' | 'T400' | 'T500' | 'T600' | 'T700' | 'T800' | 'T900';
  /** Change line-height */
  lineHeight?:
    | 'T100'
    | 'T100-med'
    | 'T200'
    | 'T200-med'
    | 'T300'
    | 'T400'
    | 'T500'
    | 'T600'
    | 'T700'
    | 'T800'
    | 'T900';
  /** Changes the font weight */
  weight?: 'light' | 'regular' | 'medium' | 'bold' | 'heavy';
  /** Aligns the text */
  alignment?: 'center' | 'center-sm';
  /** Changes the font style to italic */
  italic?: boolean;
  /** Changes the text color */
  color?: TextColor;
  /** Transforms the text */
  transform?: 'uppercase' | 'lowercase' | 'capitalize';
  /** Adds a text decoration */
  decoration?: 'underline' | 'strikethrough';
  /** Adds a margin bottom to the text */
  marginBottom?: boolean;
  children: ReactNode;
}

export const Text: FC<TextProps> = ({
  as: Element = 'p',
  size = 'T300',
  lineHeight,
  weight,
  alignment,
  italic,
  color,
  transform,
  decoration,
  marginBottom,
  children,
  className,
  ...props
}) => (
  <Element
    className={clsx(
      {
        [styles.type]: true,
        [styles['type--align-center']]: alignment === 'center',
        [styles['type--align-center-sm']]: alignment === 'center-sm',
        [styles['type--style-italic']]: italic,
        [styles['type--color-default']]: color === 'default',
        [styles['type--color-inherit']]: color === 'inherit',
        [styles['type--color-success']]: color === 'success',
        [styles['type--color-error']]: color === 'error',
        [styles['type--color-warning']]: color === 'warning',
        [styles['type--color-info']]: color === 'info',
        [styles['type--color-light']]: color === 'light',
        [styles['type--color-dark']]: color === 'dark',
        [styles['type--transform-uppercase']]: transform === 'uppercase',
        [styles['type--transform-lowercase']]: transform === 'lowercase',
        [styles['type--transform-capitalize']]: transform === 'capitalize',
        [styles['type--decoration-underline']]: decoration === 'underline',
        [styles['type--decoration-strikethrough']]:
          decoration === 'strikethrough',
        [styles['type--size-T900']]: size === 'T900',
        [styles['type--size-T800']]: size === 'T800',
        [styles['type--size-T700']]: size === 'T700',
        [styles['type--size-T600']]: size === 'T600',
        [styles['type--size-T500']]: size === 'T500',
        [styles['type--size-T400']]: size === 'T400',
        [styles['type--size-T300']]: size === 'T300',
        [styles['type--size-T200']]: size === 'T200',
        [styles['type--line-height-900']]: lineHeight === 'T900',
        [styles['type--line-height-800']]: lineHeight === 'T800',
        [styles['type--line-height-700']]: lineHeight === 'T700',
        [styles['type--line-height-600']]: lineHeight === 'T600',
        [styles['type--line-height-500']]: lineHeight === 'T500',
        [styles['type--line-height-400']]: lineHeight === 'T400',
        [styles['type--line-height-300']]: lineHeight === 'T300',
        [styles['type--line-height-200-med']]: lineHeight === 'T200-med',
        [styles['type--line-height-200']]: lineHeight === 'T200',
        [styles['type--line-height-100-med']]: lineHeight === 'T100-med',
        [styles['type--line-height-100']]: lineHeight === 'T100',
        [styles['type--weight-light']]: weight === 'light',
        [styles['type--weight-regular']]: weight === 'regular',
        [styles['type--weight-medium']]: weight === 'medium',
        [styles['type--weight-bold']]: weight === 'bold',
        [styles['type--weight-heavy']]: weight === 'heavy',
        [styles['type--margin-bottom']]: marginBottom,
      },
      className
    )}
    {...props}
  >
    {children}
  </Element>
);
