import clsx from 'clsx';

import type { ComponentProps, FC, ReactNode } from 'react';

import styles from './section.module.scss';

export interface SectionProps extends Omit<ComponentProps<'section'>, 'ref'> {
  verticalRhythm?: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
  layout?: 'spacious' | 'cozy' | 'full-width' | 'comfy';
  variant?: 'primary' | 'secondary' | 'transparent';
  fullBleed?: boolean;
  reducedSpacing?: 'top' | 'bottom';
  align?: 'left' | 'center' | 'right';
  children: ReactNode;
  sticky?: boolean;
  shadow?: boolean;
}

export const Section: FC<SectionProps> = ({
  verticalRhythm = 'md',
  layout = 'cozy',
  align,
  variant,
  fullBleed,
  reducedSpacing,
  children,
  className,
  sticky,
  shadow,
  ...props
}) => (
  <div
    className={clsx({
      [styles['section']]: true,
      // Vertical Rhythm
      [styles['section--vertical-rhythm-xs']]: verticalRhythm === 'xs',
      [styles['section--vertical-rhythm-sm']]: verticalRhythm === 'sm',
      [styles['section--vertical-rhythm-md']]: verticalRhythm === 'md',
      [styles['section--vertical-rhythm-lg']]: verticalRhythm === 'lg',
      [styles['section--vertical-rhythm-xl']]: verticalRhythm === 'xl',
      [styles['section--vertical-rhythm-2xl']]: verticalRhythm === '2xl',
      [styles['section--vertical-rhythm-3xl']]: verticalRhythm === '3xl',
      // Layout
      [styles['section--layout-cozy']]: layout === 'cozy',
      [styles['section--layout-comfy']]: layout === 'comfy',
      [styles['section--layout-full-width']]: layout === 'full-width',
      // Variant
      [styles['section--variant-secondary']]: variant === 'secondary',
      [styles['section--variant-transparent']]: variant === 'transparent',
      // Full Bleed
      [styles['section--full-bleed']]: fullBleed,
      // Reduced Spacing
      [styles['section--reduced-spacing-top']]: reducedSpacing === 'top',
      [styles['section--reduced-spacing-bottom']]: reducedSpacing === 'bottom',
      // Sticky
      [styles['section--sticky']]: sticky,
      [styles['section--shadow']]: shadow,
    })}
    {...props}
  >
    <div
      className={clsx({
        // Align
        [styles['section__container']]: true,
        [styles['section__container--center']]: align === 'center',
        [styles['section__container--right']]: align === 'right',
      })}
    >
      <div className={clsx(styles['section__content'], className)}>
        {children}
      </div>
    </div>
  </div>
);
