'use client';

import clsx from 'clsx';

import Link from 'next/link';
import type { ComponentProps } from 'react';
import { forwardRef } from 'react';

import styles from './button.module.scss';
import type { _ButtonProps } from './button.props';

export interface ButtonLinkProps
  extends _ButtonProps,
    Omit<ComponentProps<'a'>, 'className'> {}

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      children,
      decoration,
      variant,
      icon,
      iconOnly,
      iconPosition,
      layout,
      expand,
      href,
      ...props
    },
    ref
  ) => (
    <Link
      ref={ref}
      href={href ?? ''}
      className={clsx({
        [styles.button]: true,
        [styles['button--primary']]: variant === 'primary',
        [styles['button--secondary']]: variant === 'secondary',
        [styles['button--tertiary']]: variant === 'tertiary',
        [styles['button--destructive']]: variant === 'destructive',
        [styles['button--solid']]: decoration === 'solid',
        [styles['button--outline']]: decoration === 'outline',
        [styles['button--transparent']]: decoration === 'transparent',
        [styles['button--layout-full-width']]: layout === 'full-width',
        [styles['button--icon']]: !!icon,
        [styles['button--icon-only']]: !!iconOnly,
        [styles['button--icon-start']]: iconPosition === 'start',
        [styles['button--icon-end']]: iconPosition === 'end',
        [styles['button--expand']]: expand,
      })}
      {...props}
    >
      {icon && <span className={styles['button__icon']}>{icon}</span>}
      <span className={styles['button__text']}>{children}</span>
    </Link>
  )
);

ButtonLink.displayName = 'ButtonLink';
