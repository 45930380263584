import dynamic from 'next/dynamic';
import { ComponentProps, FC, ReactNode } from 'react';

export interface ThemeSwitcherProps
  extends Omit<ComponentProps<'div'>, 'className'> {
  className?: string;
  theme: 'vm' | 'vmo2' | 'o2' | 'o2_rebrand';
  children: Readonly<ReactNode>;
}

export const Themes = {
  vm: dynamic(() => import('../vm-theme')),
  vmo2: dynamic(() => import('../vmo2-theme')),
  o2: dynamic(() => import('../o2-theme')),
  o2_rebrand: dynamic(() => import('../o2-rebrand-theme')),
};

export const ThemeSwitcher: FC<ThemeSwitcherProps> = ({
  theme,
  children,
  className,
  ...props
}) => {
  const Theme = Themes[theme] || Themes.o2_rebrand;
  return (
    <Theme className={className} {...props}>
      {children}
    </Theme>
  );
};
