import React, { CSSProperties, FC } from 'react';

interface FlexProps extends React.HTMLAttributes<HTMLDivElement> {
  flexDirection?: CSSProperties['flexDirection'];
  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: CSSProperties['alignItems'];
  flexWrap?: CSSProperties['flexWrap'];
  flexGrow?: CSSProperties['flexGrow'];
  flexShrink?: CSSProperties['flexShrink'];
  flexBasis?: CSSProperties['flexBasis'];
  alignContent?: CSSProperties['alignContent'];
  gap?: CSSProperties['gap'];
  height?: CSSProperties['height'];
}

const Flex: FC<FlexProps> = ({
  flexDirection,
  justifyContent,
  alignItems,
  flexWrap,
  flexGrow,
  flexShrink,
  flexBasis,
  alignContent,
  gap,
  height,
  style,
  children,
  ...rest
}) => {
  const flexStyle: CSSProperties = {
    display: 'flex',
    flexDirection,
    justifyContent,
    alignItems,
    flexWrap,
    flexGrow,
    flexShrink,
    flexBasis,
    alignContent,
    gap,
    height,
    ...style,
  };

  return (
    <div style={flexStyle} {...rest}>
      {children}
    </div>
  );
};

export default Flex;
