'use client';

import { ButtonLink } from '@/components/momentum/components/button/button-link';
import Flex from '@/components/momentum/components/flex-box';
import { Section } from '@/components/momentum/components/section';
import { Text } from '@/components/momentum/components/text';
import { ThemeSwitcher } from '@/components/momentum/theme/theme-switcher';

import styles from './error.module.css';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <html>
      <body>
        <ThemeSwitcher theme='o2_rebrand' className={styles['page']}>
          <Section layout='cozy'>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              gap={32}
              height='100vh'
            >
              <Text as='h2' size='T900' weight='heavy'>
                Uh oh...
              </Text>
              <Text as='p' size='T500'>
                Oops.. something’s gone wrong.
              </Text>
              <ButtonLink href='/' variant='primary' decoration='outline'>
                Go to our homepage
              </ButtonLink>
            </Flex>
          </Section>
        </ThemeSwitcher>
      </body>
    </html>
  );
}
